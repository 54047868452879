import "ka-table/style.css";

import React, { useState, useEffect } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { ICellTextProps } from 'ka-table/props';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import { openEditor } from 'ka-table/actionCreators';
import './App.css';

import { HubConnectionBuilder } from '@microsoft/signalr';

// const signalRUrl = process.env.REACT_APP_AZURE_SIGNALR_URL;
// const signalRToken = process.env.REACT_APP_AZURE_SIGNALR_TOKEN;

const signalRUrl = "https://jcbw-testing-pubsub.service.signalr.net/client/?hub=serverlesssample";
const signalRToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6Ii0xNTUxMTgzNzc0IiwidHlwIjoiSldUIn0.eyJuYmYiOjE2MzM5MjA2MjUsImV4cCI6MTYzMzkyNDIyNSwiaWF0IjoxNjMzOTIwNjI1LCJhdWQiOiJodHRwczovL2pjYnctdGVzdGluZy1wdWJzdWIuc2VydmljZS5zaWduYWxyLm5ldC9jbGllbnQvP2h1Yj1zZXJ2ZXJsZXNzc2FtcGxlIn0.vnIo6ivYOekxFQxqPNWJlxjv7LEw-j_uodcNXAzTd6k";
const dataArray = [];
let dataArray2 = [
  {
    number: 1,
    horse: "My Ecstatic",
    win: 0,
    place: 0
  }, {
    number: 2,
    horse: "Young Passion",
    win: 0,
    place: 0
  }, {
    number: 3,
    horse: "Beauty Amigo",
    win: 0,
    place: 0
  }, {
    number: 4,
    horse: "Whiskey Neat",
    win: 0,
    place: 0
  }, {
    number: 5,
    horse: "Good News",
    win: 0,
    place: 0
  }, {
    number: 6,
    horse: "Strathmoretoo",
    win: 0,
    place: 0
  }, {
    number: 7,
    horse: "Witty Scholar",
    win: 0,
    place: 0
  }, {
    number: 8,
    horse: "Forever",
    win: 0,
    place: 0
  }, {
    number: 9,
    horse: "Touch Faith",
    win: 0,
    place: 0
  }, {
    number: 10,
    horse: "Mega Bonus",
    win: 0,
    place: 0
  }, {
    number: 11,
    horse: "Super Flames",
    win: 0,
    place: 0
  }
];

let isRaceDemo = true;

const tablePropsInit = {
  columns: [
    { key: 'count', title: '#', dataType: DataType.String },
    { key: 'datetime', title: 'Sent time', dataType: DataType.String },
    { key: 'channel', title: 'Receive time', dataType: DataType.String },
    { key: 'message', title: 'Difference', dataType: DataType.String },
  ],
  data: dataArray,
  editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
};

const tablePropsInit2 = {
  columns: [
    { key: 'number', title: '#', dataType: DataType.String },
    { key: 'horse', title: 'Horse name', dataType: DataType.String },
    { key: 'win', title: 'Win', dataType: DataType.String },
    { key: 'place', title: 'Place', dataType: DataType.String },
  ],
  data: dataArray2,
  editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
};

// const CustomCell: React.FC<ICellTextProps> = ({
//   column,
//   dispatch,
//   rowKeyValue,
//   value,
// }) => {
//   return (
//     <div onClick={() => {
//       dispatch(openEditor(rowKeyValue, column.key));
//     }} className={value ? 'custom-cell-demo-loyal' : 'custom-cell-demo-no-loyal'}>
//       {value ? 'Loyal Program Member' : 'No Loyal Programm'}
//     </div>
//   );
// };

function App() {
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const [tableProps2, changeTableProps2] = useState(tablePropsInit2);
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  const [connection, setConnection] = useState(null);
  const [connection2, setConnection2] = useState(null);

  const apiBaseUrl = signalRUrl;

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(apiBaseUrl, {
        accessTokenFactory: () => {
          return signalRToken
        }
      })
      .withAutomaticReconnect()
      .build();

    const newConnection2 = new HubConnectionBuilder()
      .withUrl(apiBaseUrl, {
        accessTokenFactory: () => {
          return signalRToken
        }
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    setConnection2(newConnection2);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          // console.log(result)
          connection.on('measure/latency', message => {
            // console.log(message);
            const payload = JSON.parse(message);
            const now = new Date();
            const sentTime = new Date(payload.sentTime);
            const diff = now - sentTime;

            dataArray.unshift({
              count: `${payload.count}`,
              datetime: `${now.toISOString()}`,
              channel: `${sentTime.toISOString()}`,
              message: `${diff}`,
            })
            if (!isRaceDemo) {
              changeTableProps(prevState => ({
                ...prevState,
                data: dataArray
              }));
            }

          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection]);

  useEffect(() => {
    if (connection2) {
      connection2.start()
        .then(result => {
          console.log(result)
          connection2.on('public/odds/20211001ST/ra/0', message => {
            // console.log(message);
            const payload = JSON.parse(message);
            // console.log(payload);

            const ret = payload.topic ? payload.topic.split('/') : [];
            if (ret.length > 3) {
              const horse = parseInt(ret[ret.length - 1]) + 1;
              const isWin = ret[ret.length - 2] === "win" ? true : false;
              const odds = payload.value;
              handleOddsUpdate(horse, isWin, odds);
            }

          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection2]);

  const handleOddsUpdate = (horse, isWin, odds) => {
    
    // console.log('dataArray2: ', dataArray2);
    dataArray2 = dataArray2.map(item => {
      // console.log(item.number, horse, item.number == horse);
      // console.log(horse, item.number, isWin, odds, item.number == horse);
      return item.number == horse ?
        {
          ...item,
          win: isWin ? odds : item.win,
          place: !isWin ? odds : item.place,
          winUpdated: isWin,
          placeUpdated: !isWin
        } : item
    }

    );
    // console.log(dataArray2);

    changeTableProps2(prevState => ({
      ...prevState,
      data: dataArray2
    }));

    setTimeout(() => {
      dataArray2 = dataArray2.map(item => {
        return {
          ...item,
          winUpdated: false,
          placeUpdated: false
        }
      });

      changeTableProps2(prevState => ({
        ...prevState,
        data: dataArray2
      }))
    }, 500);
  };

  const handleClickLatency = () => {
    setConnection(connection);
    isRaceDemo = false;
    changeTableProps(prevState => ({
      ...prevState,
      data: dataArray
    }))
  };

  const handleClickRaceDemo = () => {
    setConnection2(connection2);
    isRaceDemo = true;
    changeTableProps(prevState => ({
      ...prevState,
      data: dataArray2
    }));
  };

  return (
    <>
      <h1>Solace MQTT/ Azure Signal-R Demo</h1>

      {isRaceDemo ?
        <h3>Race Meeting: 01/10/2021, Race 1, Class 4 - 1200M</h3> : null
      }
      <button onClick={handleClickLatency}>
        Latency
      </button>
      <button onClick={handleClickRaceDemo}>
        Race demo
      </button>
      {!isRaceDemo ?
        <Table
          {...tableProps}
          childComponents={{
            dataRow: {
              elementAttributes: ({ rowData }) => ({
                // style: {
                //   backgroundColor: rowData.updated ? 'rgba(0, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)'
                // },
                title: `${rowData.name}: ${rowData.score}`
              })
            }
          }}
          dispatch={dispatch}
        />
        : <Table
          {...tableProps2}
          childComponents={{
            dataRow: {
              elementAttributes: ({ rowData }) => ({
                // style: {
                //   backgroundColor: rowData.updated ? 'rgba(0, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)'
                // },
                title: `${rowData.name}: ${rowData.score}`
              })
            },
            cell: {
              elementAttributes: ({ rowData, column }) => ({
                className: (column.key === 'win' && rowData.winUpdated) || (column.key === 'place' && rowData.placeUpdated) ? 'updated' : ''
              })
            }
          }}
          dispatch={dispatch}
        />
      }
    </>
  );
}

export default App;
